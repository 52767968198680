import React, { useEffect, useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsyncThunk } from '../store/auth/authSlice';
import { Toast } from 'primereact/toast';
import { useThemeContext } from '../context/useThemeContext';
import { Dropdown } from 'primereact/dropdown';
import { getCompaniesByUserAsyncThunk, setSelectCompany } from '../store/company/userCompanySlice';
import { removeToken, removeUser, removeCompanyId } from '../service/tokenServices';
import { setStoreUser, setIsAuthenticated } from '../store/auth/authSlice';
import { useNavigate } from 'react-router-dom';

function LoginForm() {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const toast = useRef(null);
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [pending, setPending] = useState(false);
    const [showPassword, setShowPassword] = useState(true);

    const { colorScheme } = useThemeContext();
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPending(true);
        const control = await dispatch(loginAsyncThunk({ email, password }));
        if (control.type === 'auth/login/fulfilled') {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Giriş Yapıldı.' });
        } else if (control.type === 'auth/login/rejected') {
            toast.current.show({ severity: 'error', summary: 'Giriş Başarısız', detail: 'E-Mail veya Şifre Hatalı.' });
        }
        setPending(false);
    };

    const handleClear = () => {
        setEmail('');
        setPassword('');
        removeCompanyId();
        removeToken();
        removeUser();
        dispatch(setStoreUser(null));
        dispatch(setIsAuthenticated(false));
    };

    return (
        <div>
            <Toast ref={toast} />
            <form className="flex flex-column" onSubmit={handleSubmit}>
                <div className="flex align-items-center mb-6 logo-container">
                    <img src={`assets/layout/images/i-uyum-logo-${colorScheme === 'light' ? 'light' : 'dark'}.png`} className="login-appname w-10rem h-3rem" alt="login-appname" />
                </div>
                <div className="form-container">
                    <span className="p-input-icon-left">
                        <i className="pi pi-envelope"></i>
                        <InputText value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="E-mail" disabled={isAuthenticated} />
                    </span>
                    <span className="p-input-icon-left">
                        <i className="pi pi-key"></i>
                        <InputText value={password} onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'password' : 'text'} placeholder="Şifre" disabled={isAuthenticated} />
                        <Button
                            type="button"
                            className="p-button-text p-button-sm p-0 text-sky-600 ml-2 absolute top-0 h-full"
                            style={{ left: '20rem' }}
                            icon={showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </span>
                </div>
                {isAuthenticated && (
                    <div className="flex w-min">
                        <Button type="button" onClick={() => handleClear()} label="Temizle" icon="pi pi-eraser" severity="success" className="text-sm text-white" />
                    </div>
                )}
                <div className="button-container mt-4">
                    <Button type="submit" label="Giriş Yap" loading={pending} disabled={isAuthenticated}></Button>
                </div>
            </form>
            <div className="button-container">
                <Button onClick={() => navigate('/forgotPassword')} link label="Şifremi Unuttum" className="text-sm text-sky-600 flex" />
            </div>
        </div>
    );
}

function SelectCompanyDropDown() {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const { companyList } = useSelector((state) => state.userCompany);

    const [selectedCompany, setSelected] = useState(null);
    const [companyOptions, setCompanyOptions] = useState([]);
    const toast = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getCompaniesByUserAsyncThunk());
        }
    }, [isAuthenticated, dispatch]);

    useEffect(() => {
        if (companyList.length > 0) {
            getCompany();
        }
    }, [companyList]);

    const getCompany = () => {
        if (companyList.length > 0) {
            let copy = [];

            companyList.forEach((company) => {
                copy.push({ name: company.name, value: company.id });
            });

            setCompanyOptions(copy);
        }
    };

    const handleSelectCompany = (e) => {
        setSelected(e.value);
        let company = companyList.find((company) => company.id === e.value);

        for (let i = 0; i < company.modules.length; i++) {
            if (company.modules[i].setting === null) {
                setSelected(null);
                toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Şirketin modülüne program eklenmemiş.' });
                return;
            }
        }

        dispatch(setSelectCompany(company));
    };

    if (companyList.length === 0 || !isAuthenticated) {
        return null;
    }

    return (
        <div className="card flex flex-column w-min p-4">
            <Toast ref={toast} />
            <label className="text-left mb-3">Devam etmek için şirket seçiniz</label>
            <Dropdown filter emptyFilterMessage="Şirket Bulunamadı" value={selectedCompany} onChange={handleSelectCompany} options={companyOptions} optionLabel="name" placeholder="Şirket seçiniz..." className="w-20rem text-left" />
        </div>
    );
}

export const Login = () => {
    const { colorScheme } = useThemeContext();

    return (
        <div className="login-body">
            <div className="login-image">
                <img src={`assets/layout/images/pages/login-${colorScheme === 'light' ? 'ondark' : 'onlight'}.png`} alt="" />
            </div>
            <div className="login-panel p-fluid flex flex-column">
                <LoginForm />
                <SelectCompanyDropDown />
            </div>
        </div>
    );
};
