import axios from 'axios';
import { auth } from '../url.js';

export const login = async (email, password) => {
    return await axios.post(auth.login, { email, password });
};

export const logout = async () => {
    return await axios.post(auth.logout);
};

export const register = async (email, name, password) => {
    return await axios.post(auth.register, { email, name, password });
};

export const forgotPassword = async (email) => {
    return await axios.post(auth.forgotPassword, { email });
};

export const resetPassword = async (email, reset_key, password) => {
    return await axios.post(auth.resetPassword, { email, reset_key, password });
};
