import React from 'react';

import { useThemeContext } from './context/useThemeContext';

const AppFooter = () => {
    const { colorScheme } = useThemeContext();
    return (
        <div className="layout-footer">
            <div className="footer-logo-container">
                <img id="footer-logo" src={`assets/layout/images/i-uyum-logo-${colorScheme === 'light' ? 'ikon' : 'ikon'}.png`} alt="atlantis-layout" />
                <span className="app-name"> - UYUM</span>
            </div>
        </div>
    );
};

export default AppFooter;
