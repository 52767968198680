import { Button } from 'primereact/button';

import { useSelector } from 'react-redux';
import { useThemeContext } from '../../context/useThemeContext';
import { Divider } from 'primereact/divider';

import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useState, useRef, useMemo } from 'react';
import { createTicket, getAllTickets } from '../../service/request/ticketsRequest';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import Loading from '../Loading';

import Chat from './ChatDialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function SelectModule() {
    const { selectedCompany } = useSelector((state) => state.userCompany);
    const [tickets, setTickets] = useState(null);
    const [chatVisible, setChatVisible] = useState(false);
    const [addTicketDialog, setAddTicketDialog] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedModule, setSelectedModule] = useState(null);
    const [selectedTicketId, setSelectedTicketId] = useState(null);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [loading, setLoading] = useState(true);

    const toast = useRef(null);

    const { colorScheme } = useThemeContext();

    const announcement = [
        {
            name: 'Datasoft i-Uyum Defter Gönderim Eğitimi',
            date: '24.04.2024',
            hour: '14:00-15:00',
            link: 'https://uyumakademi.com/webinar/Fpo5'
        },
        {
            name: 'Luca i-Uyum Defter Gönderim Eğitimi',
            date: '25.04.2024',
            hour: '14:00-15:00',
            link: 'https://uyumakademi.com/webinar/Fpdw'
        },
        {
            name: 'Luca i-Uyum Defter Gönderim Eğitimi',
            date: '2.05.2024',
            hour: '10:00-11:00',
            link: 'https://uyumakademi.com/webinar/Fpdx'
        },
        {
            name: 'Luca i-Uyum Defter Gönderim Eğitimi',
            date: '6.05.2024',
            hour: '14:00-15:00',
            link: 'https://uyumakademi.com/webinar/Fpdm'
        }
    ];

    const getAllTicketsAsync = async () => {
        const response = await getAllTickets();
        setTickets(response.data.data);
        setLoading(false);
    };

    useEffect(() => {
        getAllTicketsAsync();
    }, []);

    const getModuleTitle = (id) => {
        const module = selectedCompany?.modules?.find((module) => module.id === id);
        return module?.title;
    };

    const getSeverity = (status, unread) => {
        switch (status) {
            case 'open':
                return (
                    <div className="flex justify-content-center">
                        <h6 className="m-0 p-badge p-overlay-badge p-badge-success text-white">
                            Açık <span className="p-badge p-badge-danger">{unread}</span>
                        </h6>
                    </div>
                );

            case 'closed':
                return (
                    <div className="flex justify-content-center">
                        <h6 className="m-0 p-badge p-overlay-badge p-badge-danger text-white">
                            Kapalı <span className="p-badge p-badge-danger">{unread}</span>
                        </h6>
                    </div>
                );

            case 'pending':
                return (
                    <div className="flex justify-content-center">
                        <h6 className="m-0 p-badge p-overlay-badge p-badge-info text-white">
                            Beklemede <span className="p-badge p-badge-danger">{unread}</span>
                        </h6>
                    </div>
                );

            case 'in_progress':
                return (
                    <div className="flex justify-content-center">
                        <h6 className="m-0 p-badge p-overlay-badge p-badge-warning text-white">
                            İşlemde <span className="p-badge p-badge-danger">{unread}</span>
                        </h6>
                    </div>
                );

            default:
                return null;
        }
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className="flex align-items-center gap-5">
                <h2 className="mb-3">Destek Talepleri</h2>
            </div>
            <div>
                <Button label="Yeni Destek Talebi Oluştur" icon="pi pi-plus" className="p-button-success p-button-rounded p-mr-2 text-white mr-4" onClick={() => setAddTicketDialog(true)} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const formatDate = (inputDateTime) => {
        const dateTimeObj = new Date(inputDateTime);

        const formattedDateTime = dateTimeObj.toLocaleString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        return formattedDateTime;
    };

    const itemTemplate = (product) => {
        return (
            <div>
                <div>
                    <Button
                        icon="pi pi-comments"
                        className="p-button-rounded"
                        tooltip="Mesajlar"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        onClick={() => {
                            setChatVisible(true);
                            setSelectedTicketId(product.id);
                            setSelectedTicket(product);
                        }}
                    ></Button>
                </div>
            </div>
        );
    };

    const substringDescription = (description) => {
        if (description.length > 50) {
            const substring = description.substring(0, 50);
            return substring + '...';
        } else {
            return description;
        }
    };

    const getModule = useMemo(() => {
        const copy = [];
        selectedCompany?.modules.forEach((module) => {
            copy.push({ value: module.id, label: module.title });
        });
        return copy;
    }, [selectedCompany?.modules]);

    const handleCreateTicket = async () => {
        const data = {
            title: title,
            description: description,
            module_id: selectedModule,
            company_id: selectedCompany.id
        };

        setAddTicketDialog(false);

        try {
            await createTicket(data);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Destek talebi oluşturuldu', life: 3000 });
            await getAllTicketsAsync();
        } catch (err) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: err.response.data.message, life: 3000 });
        }

        setTitle('');
        setDescription('');
        setSelectedModule(null);
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    return (
        <div className="card h-full flex justify-content-center align-items-center">
            <div className="flex justify-content-center align-items-center w-full">
                <img src={`assets/layout/images/i-uyum-logo-${colorScheme === 'light' ? 'light' : 'dark'}.png`} className="app-name flex w-10rem h-3rem" alt="" />
                <Divider align="center" type="solid" layout="vertical" />
                <span className="pi pi-phone mr-3 mt-2 text-3xl"></span>
                <h2 className="text-center m-0">0850 222 00 52</h2>
                {/* <div className="card w-full">
                    <DataTable
                        value={announcement}
                        className='w-full'
                        showGridlines
                        header={
                            <div>
                                <h3 className="mb-3 text-white">Duyurular</h3>
                            </div>
                        }
                    >
                        <Column field="name" className='py-4' header="Eğitim Adı"></Column>
                        <Column field="date" className='py-4' header="Eğitim Tarihi"></Column>
                        <Column field="hour" className='py-4' header="Eğitim Saati"></Column>
                        <Column
                            field="link"
                            header="Kayıt Linki"
                            className='py-4'
                            body={(rowData) => (
                                <div>
                                    <a href={rowData.link}>{rowData.link}</a>
                                </div>
                            )}
                        ></Column>
                    </DataTable>
                </div>*/}
            </div>
            {/*
            burası destek talebi, geri alınırsa üstteki divi kaldır, classnamei card yap
            <Toast ref={toast} />
            <DataTable
                value={tickets}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 100, 200]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{totalRecords} destek talebinden {first} ila {last} arası gösteriliyor"
                globalFilter={globalFilter}
                header={header}
                emptyMessage="Destek Talebi Bulunamadı"
                className="p-datatable-gridlines"
            >
                <Column field="module_id" header="Modül" sortable style={{ maxWidth: '8rem' }} body={(rowData) => getModuleTitle(rowData.module_id)}></Column>
                <Column field="title" header="Başlık" sortable style={{ minWidth: '14rem' }}></Column>
                <Column field="description" header="Açıklama" sortable style={{ minWidth: '20rem' }} body={(rowData) => substringDescription(rowData.description)}></Column>
                <Column field="created_at" header="Oluşturulma Tarihi" sortable style={{ maxWidth: '8rem' }} body={(rowData) => formatDate(rowData.created_at, 'dot')}></Column>
                <Column field="status" header="Durumu / Okunmamış Mesajlar" sortable style={{ maxWidth: '8rem' }} body={(rowData) => getSeverity(rowData.status, rowData.unread)}></Column>
                <Column header="İşlemler" body={itemTemplate} className="text-center" exportable={false} style={{ minWidth: '5rem' }}></Column>
            </DataTable>
            <Chat visible={chatVisible} onHide={() => setChatVisible(false)} ticketId={selectedTicketId} selectedTicket={selectedTicket} />
            {addTicketDialog && (
                <Dialog
                    visible={addTicketDialog}
                    onHide={() => setAddTicketDialog(false)}
                    style={{
                        width: '36vw'
                    }}
                    header="Yeni Destek Talebi Oluştur"
                    footer={
                        <div>
                            <Button label="Gönder" icon="pi pi-check" className="p-button-success p-button-rounded text-white" onClick={() => handleCreateTicket()} />
                        </div>
                    }
                >
                    <div>
                        <div className="mb-4">
                            <div className="mb-2">
                                <label>Modül</label>
                            </div>

                            <div>
                                <Dropdown
                                    dataKey="value"
                                    value={selectedModule}
                                    onChange={(e) => setSelectedModule(e.value)}
                                    options={getModule}
                                    optionLabel="label"
                                    placeholder="Modül Seçiniz"
                                    filter
                                    className="w-full"
                                    emptyMessage="Modül Bulunamadı"
                                    emptyFilterMessage="Modül Bulunamadı"
                                />
                            </div>
                        </div>
                        <div className="my-2">
                            <div className="w-full mb-2">
                                <label>Talep Başlığı</label>
                            </div>
                            <div className="w-full">
                                <InputText className="w-full mb-4" type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="İstek talebi başlığı" required />
                            </div>
                            <div className="w-full mb-2">
                                <label>Talep İçeriği</label>
                            </div>
                            <div className="w-full">
                                <InputTextarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="İstek talebi içeriği" className="w-full" rows={10} />
                            </div>
                        </div>
                    </div>
                </Dialog>
            )} */}
        </div>
    );
}
