import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import AppMenu from './AppMenu';
import { Toast } from 'primereact/toast';

import { useThemeContext } from './context/useThemeContext';

import { removeToken, removeUser, removeCompanyId } from './service/tokenServices';
import { Avatar } from 'primereact/avatar';

import { useSelector } from 'react-redux';
import UpdatePasswordDiaolog from './pages/UpdatePasswordDialog';
import { updateProfile } from './service/request/userRequest';

const AppTopbar = (props) => {
    const { colorScheme, onColorSchemeChange } = useThemeContext();
    const { selectedCompany } = useSelector((state) => state.userCompany);
    const [passwordChangeDialog, setPasswordChangeDialog] = useState(false);
    const toast = useRef(null);

    const onTopbarSubItemClick = (event) => {
        event.preventDefault();
    };

    const navigate = useNavigate();

    const handleChangeTheme = () => {
        if (colorScheme === "light") {
            onColorSchemeChange("dark");
        } else {
            onColorSchemeChange("light");
        }
    }

    const handleLogout = (e) => {
        e.preventDefault();
        removeToken();
        removeUser();
        removeCompanyId();
        window.location.reload();
    };

    const handleUpdatePassword = async (newPassword) => {
        setPasswordChangeDialog(false);
        try {
            await updateProfile({ password: newPassword });
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şifre Güncellendi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link mr-4" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>
                    <button className="p-link" onClick={() => navigate('/dashboard')}>
                        <img src={`assets/layout/images/i-uyum-logo-${colorScheme === 'light' ? 'light' : 'dark'}.png`} className="app-name flex w-10rem h-3rem" alt="app-name" />
                    </button>
                </div>

                <AppMenu
                    model={props.items}
                    menuMode={props.menuMode}
                    colorScheme={props.colorScheme}
                    menuActive={props.menuActive}
                    activeInlineProfile={props.activeInlineProfile}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    toggleMenu={props.onToggleMenu}
                    onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                    onMenuClick={props.onMenuClick}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMenuItemClick={props.onMenuItemClick}
                />

                <UpdatePasswordDiaolog visible={passwordChangeDialog} onHide={() => setPasswordChangeDialog(false)} onSubmit={handleUpdatePassword} />

                <div className="layout-topbar-right">
                    <ul className="layout-topbar-right-items">
                        <li id="profile" className={classNames('profile-item', { 'active-topmenuitem': props.topbarMenuActive })}>
                            <button className="p-link flex justify-content-center align-items-center" onClick={props.onTopbarItemClick}>
                                <Avatar icon="pi pi-user" className="h-full w-full" size="large" style={{ backgroundColor: '#ffff00', color: '#251da2' }} shape="circle" />
                            </button>

                            <ul className="fadeInDown">
                                <li className="layout-inline-menu-action-item">
                                    <button className="p-link" onClick={() => setPasswordChangeDialog(true)}>
                                        <i className="pi pi-key pi-fw"></i>
                                        <span>Şifre Değiştir</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link" onClick={(e) => handleLogout(e)} style={{ margin: 0, paddingLeft: '.5rem' }}>
                                        <i className="pi pi-fw pi-sign-out"></i>
                                        <span>Çıkış Yap</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <button className="p-link" onClick={() => handleChangeTheme()}>
                                {colorScheme === 'light' ? <i className="topbar-icon pi pi-fw pi-moon"></i> : <i className="topbar-icon pi pi-fw pi-sun"></i>}
                            </button>
                        </li>
                        <li>
                            <div className="mr-2">
                                {/* <Button className="" icon="pi pi-building" /> */}
                                <span className="p-badge h-full py-2">{selectedCompany?.name.substring(0, 40) + "..."}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default AppTopbar;
