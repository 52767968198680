import React, { useRef } from 'react';
import { useThemeContext } from '../context/useThemeContext';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { Divider } from 'primereact/divider';

export const Chat = () => {
    const { colorScheme } = useThemeContext();
    const tawkMessengerRef = useRef();

    const onLoad = () => {
        tawkMessengerRef.current.maximize();
    };
    const onChatMinimized = () => {
        onLoad();
    };

    return (
        <div className="login-body">
            <div className="login-image">
                <img src={`assets/layout/images/pages/login-${colorScheme === 'light' ? 'ondark' : 'onlight'}.png`} alt="" />
            </div>
            <div className="w-full p-8 flex flex-column">
                <div className="card h-full flex justify-content-center align-items-center">
                    <div className="flex justify-content-center align-items-center w-full">
                        <img src={`assets/layout/images/i-uyum-logo-${colorScheme === 'light' ? 'light' : 'dark'}.png`} className="app-name flex w-10rem h-3rem" alt="" />
                    </div>
                </div>
                <TawkMessengerReact
                    customStyle={{
                        visibility: { position: 'tr' }
                    }}
                    onChatMinimized={onChatMinimized}
                    onLoad={onLoad}
                    className="hidden"
                    propertyId="64a3dbf294cf5d49dc616b0e"
                    widgetId="1h4g16bfg"
                    ref={tawkMessengerRef}
                />
            </div>
        </div>
    );
};
