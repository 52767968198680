import React from "react";
import iuyumgif from "../assets/flags/iuyum.gif"


export default function Loading() {
    return (
        <div className="card flex justify-content-center align-items-center h-full w-full">
            <div className="flex flex-column justify-content-center align-items-center">
                <img width={80} height={80} src={iuyumgif} alt="loading" />
                <h4 className="mb-0">İşleminiz Devam Ediyor</h4>
                <h5 className="mt-3">Lütfen Bekleyiniz</h5>
            </div>
        </div>
    );
}