import React, { useEffect, useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useThemeContext } from '../context/useThemeContext';
import { forgotPassword, resetPassword } from '../service/request/authRequest';
import { useNavigate } from 'react-router-dom';

function LoginForm() {
    const toast = useRef(null);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [resetKey, setResetKey] = useState('');
    const [pending, setPending] = useState(false);
    const [showPassword, setShowPassword] = useState(true);
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const navigate = useNavigate();

    const { colorScheme } = useThemeContext();

    const handleSubmit = async () => {
        setPending(true);
        try {
            const res = await resetPassword(email, resetKey, password);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: res?.data?.message });
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (e) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: e?.response?.data?.message });
        }
    };

    const forgotMailChecker = async () => {
        setPending(true);
        try {
            const res = await forgotPassword(email);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: res?.data?.message });
            setShowForgotPassword(true);
        } catch (e) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: e?.response?.data?.message });
        }
        setPending(false);
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="flex align-items-center mb-6 logo-container">
                <img src={`assets/layout/images/i-uyum-logo-${colorScheme === 'light' ? 'light' : 'dark'}.png`} className="login-appname w-10rem h-3rem" alt="login-appname" />
            </div>
            <h4 className="flex text-start my-5">Şifre Sıfırla</h4>
            <div className="form-container">
                <span className="p-input-icon-left">
                    <i className="pi pi-envelope"></i>
                    <InputText value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="E-mail" disabled={showForgotPassword} />
                </span>
                {showForgotPassword && (
                    <div>
                        <InputText value={resetKey} onChange={(e) => setResetKey(e.target.value)} placeholder="Doğrulama Kodu" maxLength={6} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-key"></i>
                            <InputText value={password} onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'password' : 'text'} placeholder="Yeni Şifre" />
                            <Button
                                type="button"
                                className="p-button-text p-button-sm p-0 text-sky-600 ml-2 absolute top-0 h-full"
                                style={{ left: '20rem' }}
                                icon={showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </span>
                    </div>
                )}
            </div>
            <div className="button-container mt-4">
                <Button onClick={showForgotPassword ? () => handleSubmit() : () => forgotMailChecker()} type="submit" label={showForgotPassword ? 'Sıfırla' : 'Doğrula'} loading={pending} disabled={showForgotPassword && password.length < 6}></Button>
            </div>
        </div>
    );
}

export const ForgotPassword = () => {
    const { colorScheme } = useThemeContext();

    return (
        <div className="login-body">
            <div className="login-image">
                <img src={`assets/layout/images/pages/login-${colorScheme === 'light' ? 'ondark' : 'onlight'}.png`} alt="" />
            </div>
            <div className="login-panel p-fluid flex flex-column">
                <LoginForm />
            </div>
        </div>
    );
};
